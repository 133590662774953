import http from '@/utils/http'

interface Infos {
  [propName: string]: any
}

export interface State {
  applyList: Infos[]
  checkList: Infos[]
}

const state: State = {
  applyList: [],
  checkList: [],
}
const getters = {}
const actions = {
  createApply(context: any, payload: any) {
    return http
      .post('/checks/apply', payload)
      .then((res) => {
        return { payload: res.data }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getApply(context: any, payload: any) {
    return http
      .get('/checks/apply', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateApplyList', res.data.rets)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  getCheck(context: any, payload: any) {
    return http
      .get('/checks/apply', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateCheckList', res.data.rets)
        }
        return { payload: res.data }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  putCheck(context: any, payload: any) {
    return http
      .put('/checks/apply', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateCheckState', payload)
        }
        return { payload: res.data }
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
const mutations = {
  updateApplyList(state: State, payload: Infos[]) {
    state.applyList = payload
  },
  updateCheckList(state: State, payload: Infos[]) {
    state.checkList = payload
  },
  updateCheckState(state: State, payload: any) {
    const check = state.checkList.find((v) => v._id === payload._id)
    if (check) {
      check.state = payload.state
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
