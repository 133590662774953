import http from '@/utils/http'

interface Infos {
  [propName: string]: any
}

export interface State {
  token: string
  infos: Infos
}

const state: State = {
  token: '',
  infos: {},
}
const getters = {}
const actions = {
  login(context: any, payload: any) {
    return http
      .post('/users/login', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateToken', res.data.token)
        }
        return { payload: res.data }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  infos(context: any) {
    return http
      .get('/users/infos')
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateInfos', res.data.infos)
        }
        return { payload: res.data }
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
const mutations = {
  updateToken(state: State, payload: string) {
    state.token = payload
  },
  updateInfos(state: State, payload: Infos) {
    state.infos = payload
  },
  clearToken(state: State) {
    state.token = ''
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
