import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import _ from 'lodash'
import store from '@/store'
import type { RouteRecordRaw } from 'vue-router'
import type { StateAll } from '@/store'

const state = store.state as StateAll

declare module 'vue-router' {
  interface RouteMeta {
    menu?: boolean
    title?: string
    icon?: string 
    auth?: boolean
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/Home.vue'),
    meta: {
      menu: true,
      title: '考勤管理',
      icon: 'document-copy',
      auth: true,
    },
    redirect: '/sign',
    children: [
      {
        path: '/sign',
        name: 'sign',
        component: defineAsyncComponent(() => import('@/views/Sign/Sign.vue')),
        meta: {
          menu: true,
          title: '在线打卡签到',
          icon: 'calendar',
          auth: true,
        },
      },
      {
        path: '/exception',
        name: 'exception',
        component: defineAsyncComponent(() => import('@/views/Exception/Exception.vue')),
        meta: {
          menu: true,
          title: '异常考勤查询',
          icon: 'warning',
          auth: true,
        },
      },
      {
        path: '/apply',
        name: 'apply',
        component: defineAsyncComponent(() => import('@/views/Apply/Apply.vue')),
        meta: {
          menu: true,
          title: '添加考勤审批',
          icon: 'document-add',
          auth: true,
        },
      },
      {
        path: '/check',
        name: 'check',
        component: defineAsyncComponent(() => import('@/views/Check/Check.vue')),
        meta: {
          menu: true,
          title: '我的考勤审批',
          icon: 'finished',
          auth: true,
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/Login.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const token = state.users.token
  const usersInfos = state.users.infos
  const signsInfos = state.signs.infos
  const applyList = state.checks.applyList
  const checkList = state.checks.checkList
  const info = state.news.info

  if (to.meta.auth) {
    if (token) {
      if (_.isEmpty(usersInfos)) {
        await store.dispatch('users/infos')
      }
      const _id = state.users.infos._id
      if (_.isEmpty(info)) {
        await store.dispatch('news/getRemind', { userid: _id })
      }
      if (
        _.isEmpty(signsInfos) &&
        (to.path === '/sign' || to.path === '/exception')
      ) {
        await store.dispatch('signs/getTime', { userid: _id })
      }
      if (
        _.isEmpty(applyList) &&
        (to.path === '/apply' || to.path === '/exception')
      ) {
        await store.dispatch('checks/getApply', { applicantid: _id })
        await store.dispatch('news/clearRemind', {
          userid: _id,
          applicant: false,
        })
      }
      if (_.isEmpty(checkList) && to.path === '/check') {
        await store.dispatch('checks/getCheck', { approverid: _id })
        await store.dispatch('news/clearRemind', {
          userid: _id,
          approver: false,
        })
      }
      next()
    } else {
      next('/login')
    }
  } else {
    if (token && to.path === '/login') {
      next('/sign')
    } else {
      next()
    }
  }
})

export default router
