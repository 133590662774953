import axios, { AxiosResponse, AxiosRequestHeaders, AxiosRequestConfig } from 'axios'
import store, { StateAll } from '@/store'
import { ElMessage } from 'element-plus'

const instance = axios.create({
  baseURL: 'http://api.h5ke.top/',
  timeout: 5000,
})

instance.interceptors.request.use(
  (config) => {
    (config.headers as AxiosRequestHeaders).authorization = (store.state as StateAll).users.token
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

instance.interceptors.response.use(
  (response) => {
    if(response.data.errmsg === 'token error'){
      ElMessage.error(response.data.errmsg)
      store.commit('users/clearToken')
      window.location.replace('/login')
    }
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

interface Data {
  [propName: string]: unknown
}

interface Http {
  get: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  post: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  delete: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
  put: (url: string, data?: Data, config?: AxiosRequestConfig) => Promise<AxiosResponse>
}

const http: Http = {
  get(url, data, config) {
    return instance.get(url, {
      params: data,
      ...config
    })
  },
  post(url, data, config) {
    return instance.post(url, data, config)
  },
  delete(url, data, config) {
    return instance.delete(url, {
      data,
      ...config
    })
  },
  put(url, data, config) {
    return instance.put(url, data, config)
  },
}

export default http
