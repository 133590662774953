import http from '@/utils/http'

interface Info {
  [propName: string]: any
}

export interface State {
  info: Info
}

const state: State = {
  info: {},
}
const getters = {}
const actions = {
  getRemind(context: any, payload: any) {
    return http
      .get('/news/remind', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateInfo', res.data.info)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  clearRemind(context: any, payload: any) {
    return http
      .put('/news/remind', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateInfo', res.data.info)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  sendRemind(context: any, payload: any) {
    return http
      .put('/news/remind', payload)
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
const mutations = {
  updateInfo(state: State, payload: any) {
    state.info = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
