import { createStore, useStore as baseUseStore } from 'vuex'
import VuexPersistence from 'vuex-persist'
import users from '@/store/modules/users'
import signs from '@/store/modules/signs'
import checks from '@/store/modules/checks'
import news from '@/store/modules/news'
import type { Store } from 'vuex'
import type { InjectionKey } from 'vue'
import type { State as UsersState } from '@/store/modules/users'
import type { State as SignsState } from '@/store/modules/signs'
import type { State as ChecksState } from '@/store/modules/checks'
import type { State as NewsState } from '@/store/modules/news'

interface State {
  [propName: string]: unknown
}

export interface StateAll extends State {
  users: UsersState,
  signs: SignsState,
  checks: ChecksState,
  news: NewsState
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({ users: { token: (state as StateAll).users.token } }),
})

export const key: InjectionKey<Store<StateAll>> = Symbol()

export function useStore() {
  return baseUseStore(key)
}

export default createStore<State>({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    users,
    signs,
    checks,
    news
  },
  plugins: [vuexLocal.plugin],
})

