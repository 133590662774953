import http from '@/utils/http'

interface Infos {
  [propName: string]: any
}

export interface State {
  infos: Infos
}

const state: State = {
  infos: {},
}
const getters = {}
const actions = {
  getTime(context: any, payload: any) {
    return http
      .get('/signs/time', payload)
      .then((res) => {
        if (res.data.errcode === 0) {
          context.commit('updateInfos', res.data.infos)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  },
  putTime(context: any, payload: any) {
    return http
      .put('/signs/time', payload)
      .then((res) => {
        context.commit('updateInfos', res.data.infos)
      })
      .catch((err) => {
        console.log(err)
      })
  },
}
const mutations = {
  updateInfos(state: State, payload: Infos) {
    state.infos = payload
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
